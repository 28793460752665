export const CONSTANTS = {
    ALLTOPICID: 'all',
    NOTOPICS: 'NO_TOPICS',
    all_topic: { 
        id: "all", 
        topic_description: "",
        topic_name: "All", 
        topic_sync_date: "",
        topic_uuid: "" 
    },
    CORDOVA : 'cordova',
    CLEAN_SEARCH : 'clean-search-no-data',
    DIABETES_MARKETPLACE_APP : 'marketplace'
};
